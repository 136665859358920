export const environment = {
    environmentName: 'UAT',
    production: false,
    useLocalMockData: false,
    useLocalMockDataSimulatedDelay: 500,
    useRemoteMockData: false,
    displayDevTools: true,
    displayLanguageSelector: false,
    routerEnableTracing: true,
    debugDisplayFieldJson: false,
    useAdalAuth: false, // Disabled for now, relying solely on SessionId. Will be re-enabled later.
    appInsightsInstrumentationKey: 'bdbda20e-20e1-4b8c-a9fd-6e189ee5cc6f',
    bankServiceBaseUrl: 'https://bankprofileuat-cdfydpe0f0cbamf3.z01.azurefd.net/',
    bankServiceAadAppUri: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d',
    taxServiceBaseUrl: 'https://payeeonboardinguat.westus2.cloudapp.azure.com/TaxFabricUAT/PayeeOnboarding.TaxService/',
    taxServiceAadAppUri: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d',
    basicProfileServiceBaseUrl: 'https://payeeonboardinguat.westus2.cloudapp.azure.com/BasicFabricUAT/BasicProfileService/',
    basicProfileServiceAadAppUri: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d',
    complianceServiceBaseUrl: 'https://payeeonboardinguat.westus2.cloudapp.azure.com/ComplianceFabricUAT/ComplianceService/',
    complianceServiceAadAppUri: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d',
    adalConfigRedirectUri: 'https://paymentcentral-uat.azurewebsites.net/',
    adalConfigLogOutUri: 'https://uatepportal.cloudapp.net',
    adalConfigInstance: 'https://login.windows-ppe.net/', // Default was: https://login.microsoftonline.com/
    adalConfigTenant: 'common', // This is the AAD tenant id where the client app is registered.
    adalConfigClientId: '249cc3df-0fbd-4f4f-ae0f-d32caedaee3d'
};
