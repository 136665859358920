import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { throwError as observableThrowError, Observable, ObservableInput, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as $ from 'jquery';
import { environment } from 'environments/environment';
import { AppSharedStateService } from 'app/common/services/app-shared-state.service';
import { AppSharedUtilityService } from 'app/common/services/app-shared-utility.service';
import { MiscUtil } from 'app/common/utility/miscUtil';
import { PageMetadataState } from 'app/common/utility/page-metadata-state';
import { TaxMockData, TaxMockDataProviderService, TaxMockDataDescription } from 'app/pages/tax/mockData/taxMockDataProvider.service';
import { BankMockData, BankMockDataProviderService, BankMockDataDescription } from 'app/pages/bank/mockData/bankMockDataProvider.service';
import { BasicProfileMockData, BasicProfileMockDataProviderService, BasicProfileMockDataDescription } from 'app/pages/basic-profile/mockData/basicProfileMockDataProvider.service';
import { ComplianceMockData, ComplianceMockDataProviderService, ComplianceMockDataDescription } from 'app/pages/compliance/mockData/complianceMockDataProvider.service';
import { AlertType } from 'app/common/components/message-display/alert-type';
import { MetadataApiService, SupportedMicroservice } from 'app/common/services/metadata-api.service';

interface EnvironmentConfigData {
    setting: string;
    value: string | number | boolean;
}

interface Claim {
    claimKey: string;
    value: string;
}

interface ClaimDataDropdownItem {
    resourceName: string;
    endpoint: string;
    claims: Claim[];
}

enum MockDataCategory {
    Bank, Tax, BasicProfile, Compliance
}

interface MockDataDropdownItem {
    id: number;
    description: string;
    mockDataCategory?: MockDataCategory;
    type?: TaxMockData | BankMockData | BasicProfileMockData | ComplianceMockData;
}

// This component is display only when environment.displayDevTools is true.
// It provides helpful diagnostic output of application internals.
// Note: Not localizing the dev-tools component.
@Component({
    selector: 'app-dev-tools',
    templateUrl: './dev-tools.component.html',
    styleUrls: ['./dev-tools.component.css']
})
export class DevToolsComponent implements OnInit, AfterViewInit, OnDestroy {
    public isDisplayed: boolean = false;
    public environmentConfigData: EnvironmentConfigData[] = [];
    public environmentName: string = environment.environmentName;
    public useLocalMockData: boolean = environment.useLocalMockData;
    public claimDataDropdownItems: ClaimDataDropdownItem[] = [];
    public selectedClaimDataDropdownItem: ClaimDataDropdownItem;
    public selectedEndpointBearerToken: string;
    public miscUtil: MiscUtil = MiscUtil; // For data binding in html.
    public mockDataDropdownItems: MockDataDropdownItem[] = [];
    public selectedMockDataDropdownItem: MockDataDropdownItem;
    public mockDataNothingSelected: MockDataDropdownItem = { id: -1, description: 'Select...', mockDataCategory: null, type: null };
    public legacyPopLink: string = null;
    private navigationSubscription: Subscription;

    // Property that gets the active page (ex: bank or tax) metadata state object.
    public get pageMetadataState(): PageMetadataState {
        let pageMetadataState: PageMetadataState | any = this.appSharedStateService.activePageMetadataState;
        if (!pageMetadataState) {
            pageMetadataState = { displaySections: 'No metadata found on page.' };
        }
        return pageMetadataState;
    }

    // Constructor for DevDebugComponent.
    constructor(
        // private adalService: AdalService,
        private appSharedStateService: AppSharedStateService,
        private appSharedUtilityService: AppSharedUtilityService,
        private taxMockDataProvider: TaxMockDataProviderService,
        private bankMockDataProvider: BankMockDataProviderService,
        private basicProfileMockDataProvider: BasicProfileMockDataProviderService,
        private complianceMockDataProvider: ComplianceMockDataProviderService,
        private metadataApiService: MetadataApiService,
        private router: Router) {
        // Init legacy POP link during navigation as the url will differ based on active route.
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                this.initLegacyPopLink();
            }
        });
    }

    // Angular OnInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnInit(): void {
        this.buildEnvironmentConfigData();
        this.initClaimEndpoints();


        this.initMockDataDropdownItems();
    }

    // Angular AfterViewInit handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.addTabIcons();
        });
    }

    // Angular OnDestroy handler. Part of the lifecycle hooks: https://angular.io/guide/lifecycle-hooks
    public ngOnDestroy(): void {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    // Toggle expand clicked handler.
    public toggleExpandClicked() {
        this.isDisplayed = !this.isDisplayed;
        if (this.isDisplayed) {
            $('#dev-tools-content').show(300);
        } else {
            $('#dev-tools-content').hide(300);
        }
    }

    // Mouse over gear icon.
    public mouseOverGearIcon() {
        $('#fa-gear-icon').addClass('open-close-icon-hover'); // Done beacuse we cannot rely on open-close-icon:hover as open-close-icon hides fa-gear-icon.
        $('#dev-tools-hover').show(0);
    }

    // Mouse out gear icon.
    public mouseOutGearIcon() {
        $('#fa-gear-icon').removeClass('open-close-icon-hover');
        $('#dev-tools-hover').hide(0);
    }

    // Mouse over Legacy POP icon.
    public mouseOverLegacyPop() {
        $('#legacy-pop-hover').show(0);
    }

    // Mouse out Legacy POP icon.
    public mouseOutLegacyPop() {
        $('#legacy-pop-hover').hide(0);
    }

    // Legacy POP clicked event.
    public legacyPopClicked() {
        if (!!this.legacyPopLink) {
            window.open(this.legacyPopLink); // lgtm[js/client-side-unvalidated-url-redirection] 
        } else {
            this.appSharedUtilityService.displayMessage({ message: 'No legacy POP link defined for this environment.', alertType: AlertType.Error });
        }
    }

    // Add tab icons using FontAwesome fonts.
    private addTabIcons() {
        // Note that font-awesome will replace the 'fas' marked elements with svg. Therefore need to keep span with font
        // and span with text as separate span elements.
        $('#k-tabstrip-tab-0 span').append('<span class="fas fa-list"></span><span>&nbsp;Environment Config</span>');
        $('#k-tabstrip-tab-1 span').append('<span class="fas fa-key"></span><span>&nbsp;Auth</span>');
        $('#k-tabstrip-tab-2 span').append('<span class="fas fa-database"></span><span>&nbsp;Metadata</span>');
        $('#k-tabstrip-tab-3 span').append('<span class="fab fa-wpforms"></span><span>&nbsp;Mock data</span>');
    }

    // Build environment config data.
    private buildEnvironmentConfigData() {
        for (let i: number = 0; i < Object.keys(environment).length; i++) {
            this.environmentConfigData.push({ setting: Object.keys(environment)[i], value: Object.values(environment)[i] });
        }
    }

    // Init claim endpoints.
    private initClaimEndpoints() {
        const appClaimData: ClaimDataDropdownItem = { resourceName: 'App', endpoint: environment.adalConfigClientId, claims: [] };
        this.claimDataDropdownItems.push(appClaimData);
        this.claimDataValueChange(appClaimData);

    }

    // Claim data value changed handler.
    public claimDataValueChange(claimData: ClaimDataDropdownItem): void {
        this.selectedClaimDataDropdownItem = claimData;
        this.selectedEndpointBearerToken = '';

    }

    // Init tax mock data dropdown items.
    private initMockDataDropdownItems(): void {
        let i: number = 0;
        const bankPrefix = 'Bank: ';
        const taxPrefix = 'Tax: ';
        const basicProfilePrefix = 'BasicProfile: ';
        const compliancePrefix = 'Compliance: ';

        this.mockDataDropdownItems.push(
            { id: i++, description: bankPrefix + BankMockDataDescription.generalExample, mockDataCategory: MockDataCategory.Bank, type: BankMockData.GeneralExample }
        );

        this.mockDataDropdownItems.push(
            { id: i++, description: taxPrefix + TaxMockDataDescription.controlsExample, mockDataCategory: MockDataCategory.Tax, type: TaxMockData.ControlsExample },
            { id: i++, description: taxPrefix + TaxMockDataDescription.payFromIrelandResidenceIreland, mockDataCategory: MockDataCategory.Tax, type: TaxMockData.PayFromIrelandResidenceIreland },
            { id: i++, description: taxPrefix + TaxMockDataDescription.payFromArgentinaResidenceArgentina, mockDataCategory: MockDataCategory.Tax, type: TaxMockData.PayFromArgentinaResidenceArgentina }
        );

        this.mockDataDropdownItems.push(
            { id: i++, description: basicProfilePrefix + BasicProfileMockDataDescription.generalExample, mockDataCategory: MockDataCategory.BasicProfile, type: BasicProfileMockData.GeneralExample }
        );

        this.mockDataDropdownItems.push(
            { id: i++, description: compliancePrefix + ComplianceMockDataDescription.generalExample, mockDataCategory: MockDataCategory.Compliance, type: ComplianceMockData.GeneralExample }
        );
    }

    // Tax mock data value changed handler.
    public mockDataValueChange(mockDataDropdownItem: MockDataDropdownItem): void {
        this.selectedMockDataDropdownItem = mockDataDropdownItem;

        if (mockDataDropdownItem.type === null) {
            return;
        } else if (mockDataDropdownItem.mockDataCategory === MockDataCategory.Bank) {
            this.bankMockDataProvider.changeMockData(<BankMockData>this.selectedMockDataDropdownItem.type, true);
            return;
        } else if (mockDataDropdownItem.mockDataCategory === MockDataCategory.Tax) {
            this.taxMockDataProvider.changeMockData(<TaxMockData>this.selectedMockDataDropdownItem.type, true);
            return;
        } else if (mockDataDropdownItem.mockDataCategory === MockDataCategory.BasicProfile) {
            this.basicProfileMockDataProvider.changeMockData(<BasicProfileMockData>this.selectedMockDataDropdownItem.type, true);
            return;
        } else if (mockDataDropdownItem.mockDataCategory === MockDataCategory.Compliance) {
            this.complianceMockDataProvider.changeMockData(<ComplianceMockData>this.selectedMockDataDropdownItem.type, true);
            return;
        }
    }

    // Init legacy POP link for this environment.
    private initLegacyPopLink() {
        if (this.useLocalMockData) {
            return;
        }

        // Microservice (Bank/Tax/Basic)...
        // - PPE environment points to legacy POP DEV30: dev30payeeportal.cloudapp.net
        // - UAT environment points to legacy POP UAT:   payeeportaluat.cloudapp.net
        // - PROD environment points to legacy POP PROD: payeeportalprod.cloudapp.net
        let legacyPopServer: string;
        switch (this.environmentName) {
            case 'DEV':
            case 'PPE':
                legacyPopServer = 'dev30payeeportal.cloudapp.net';
                break;
            case 'UAT':
                legacyPopServer = 'payeeportaluat.cloudapp.net';
                break;
            case 'PROD':
                legacyPopServer = 'payeeportalprod.cloudapp.net';
                break;
            default:
                return;
        }

        // Based on the current configured metadata-api.service configuration, use one of the following legacy routes:
        // BankUI, TaxUI, BasicRegistrationUI, ComplianceUI
        let legacyRoute: string;
        switch (this.metadataApiService.microService) {
            case SupportedMicroservice.Bank:
                legacyRoute = 'BankUI';
                break;
            case SupportedMicroservice.Tax:
                legacyRoute = 'TaxUI';
                break;
            case SupportedMicroservice.Basic:
                legacyRoute = 'BasicRegistrationUI';
                break;
            case SupportedMicroservice.Compliance:
                legacyRoute = 'ComplianceUI';
                break;
            default:
                return;
        }

        // Example URL:
        //   https://payeeportaluat.cloudapp.net/MZocbeM%3d.%3dMM%3d.%3dM1hGGe9JCKoWxJMT8KSa0T%3d.%3dTuV2KOCzRlRvmJQ%3d/BankUI
        this.legacyPopLink = `https://${legacyPopServer}/${this.appSharedStateService.sessionId}/${legacyRoute}`;
    }
}
